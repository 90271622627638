import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import Menu from '../../components/menuComponent';
import ContactCaptcha from '../../components/contactContainer';

const ContactContainer = ({ location }) => {
  const { pathname } = location;
  const filter = pathname.split('/').filter((pathString) => pathString.length > 0).pop();

  return (
    <Layout
      title="Virtual Design Visit | Wow Interior Design"
      description="We offer free, virtual design meetings to discuss & design your new fitted furniture while you remain at home. A member of our team will contact you to arrange a date and time that suits you."
    >
      <Menu />
      <ContactCaptcha formType={filter} />
    </Layout>
  );
};

export default ContactContainer;

ContactContainer.propTypes = {
  location: PropTypes.object.isRequired,
};
